import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>Ei löydy, ei</h1>
    <p>Köpösti kävi. Sivua ei löydy.</p>
  </Layout>
)

export default NotFoundPage
